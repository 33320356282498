<script>
import newsService from "~/service/newsService";
export default {
  name: "NewsAlert",
  render: () => null,
  data() {
    return {
      newsList: []
    };
  },
  methods: {
    async reload() {
      this.newsList = await newsService.feed();
      if (this.newsList) {
        this.newsList.forEach(async news => {
          await this.$dialog.confirm({
            text: news.descr,
            title: news.title,
            icon: false,
            actions: {
              true: {
                color: "red",
                text: "Ok",
                handle: async () => {
                  await newsService.markAsRead(news.newsId);
                }
              }
            }
          });
        });
      }
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
