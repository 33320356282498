<template>
  <v-card class="news">
    <v-card-title>{{ news.title }}</v-card-title>
    <!-- <v-card-subtitle v-if="showOpened && news.read_date" class="align-left">
      Aperta il:
      {{ news.read_date | formatDate("fromISO", null, "HH:MM dd/MM/yyyy") }}
    </v-card-subtitle>
    <v-card-subtitle v-else-if="!news.read_date" class="align-left">
      Non ancora aperta
    </v-card-subtitle> -->
    <v-card-text>
      <div class="news-text" v-html="news.descr" @click="handleLink"></div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <a v-if="news.navTo" link @click="handleLink" :href="news.navTo">
        continua a leggere
      </a>
      <v-btn
        v-if="showRead"
        color="primary"
        outlined
        @click.stop.prevent="$emit('read')"
      >
        Ok
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped lang="scss">
.products {
  .news {
    height: 170px;
    cursor: pointer;
  }
  .news-text {
    flex: 1;
    display: -webkit-box;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>
<script>
export default {
  name: "NewsCard",
  props: {
    news: { type: Object, required: true },
    showOpened: { type: Boolean, default: true },
    showRead: { type: Boolean, default: false }
  },
  methods: {
    handleLink() {
      this.$emit("read");
      let link = new URL(this.news.navTo, window.location.href);
      //utilizzare stessa tecnica della page per capire se è un link interno o no....
      if (global.config.domainWhitelist.includes(link.hostname)) {
        let resolved = this.$router.resolve({
          path: link.pathname
        });
        if (
          resolved &&
          resolved.route.matched.length > 0 &&
          resolved.route.name != "DefaultRoute"
        ) {
          this.$router.push(resolved.route);
        } else {
          openExternal(link);
        }
      } else {
        openExternal(link);
      }
      function openExternal(link) {
        if (this.$platform_is_cordova) {
          //if app open on _system browser
          window.cordova.InAppBrowser.open(link.href, "_system");
        } else {
          window.open(link, "blank");
        }
      }
    }
  }
};
</script>
